exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-algorithms-index-tsx": () => import("./../../../src/pages/algorithms/index.tsx" /* webpackChunkName: "component---src-pages-algorithms-index-tsx" */),
  "component---src-pages-algorithms-interactive-hamming-code-tsx": () => import("./../../../src/pages/algorithms/interactive/hamming-code.tsx" /* webpackChunkName: "component---src-pages-algorithms-interactive-hamming-code-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-javascript-numbers-index-tsx": () => import("./../../../src/pages/articles/javascript-numbers/index.tsx" /* webpackChunkName: "component---src-pages-articles-javascript-numbers-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-systems-logic-elements-index-tsx": () => import("./../../../src/pages/systems/logic-elements/index.tsx" /* webpackChunkName: "component---src-pages-systems-logic-elements-index-tsx" */)
}

